import { useEffect } from 'react';
import { useUserContext } from '@/context';
import { hackleClient } from '@/exportables/services/hackle.service';
import { isInternalAccount } from '@/exportables/utils/user';

export const useSetHackleUser = () => {
  const { isFetching, isLoggedIn, userInfo } = useUserContext();

  useEffect(() => {
    if (isFetching) return;

    if (!isLoggedIn) {
      hackleClient.resetUser();
      return;
    }

    if (userInfo) {
      hackleClient.setUserId(String(userInfo.id));

      const internalAccount = String(isInternalAccount(userInfo.email));
      hackleClient.setUserProperty('internal_account', internalAccount);
    }
  }, [isFetching, isLoggedIn, userInfo]);
};
