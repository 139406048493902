import axios, { AxiosRequestConfig } from 'axios';
import https from 'https';
import { GetServerSidePropsContext } from 'next';
import { getHeraBaseUrl } from '@/exportables/utils';

export const HERA_BASE_URL = getHeraBaseUrl();
export const DEFAULT_TIMEOUT = 30000;

export const createHeraClient = (config?: AxiosRequestConfig) => {
  const axiosInstance = axios.create({
    baseURL: HERA_BASE_URL,
    timeout: DEFAULT_TIMEOUT,
    withCredentials: true,
    ...config,
  });

  /**
   * Disable only in development mode.
   */
  if (process.env.NODE_ENV === 'development') {
    const httpsAgent = new https.Agent({
      rejectUnauthorized: false,
    });
    axiosInstance.defaults.httpsAgent = httpsAgent;
  }

  return axiosInstance;
};

export const createLocalClient = (config?: AxiosRequestConfig) => {
  const axiosInstance = axios.create({
    timeout: DEFAULT_TIMEOUT,
    withCredentials: true,
    ...config,
  });

  return axiosInstance;
};

export const createServerSideClient = (
  ctx: GetServerSidePropsContext,
  config?: AxiosRequestConfig
) => {
  const axiosConfig = {
    headers: ctx?.req?.headers?.cookie
      ? { cookie: ctx.req.headers.cookie }
      : undefined,
    ...config,
  };

  return createHeraClient(axiosConfig);
};

export const heraClient = createHeraClient();
export const localClient = createLocalClient();
export const axiosClient = axios.create();
