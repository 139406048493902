import Head from 'next/head';
import AppleTouchIcon from '@/assets/images/apple-icon.png';
import ShortCutIcon from '@/assets/images/favicon.png';

import ProgrammersMetaImage from '@/assets/images/img-meta-programmers.png';
import { defaultMetaData, MetaData } from '@/exportables/constants/meta';

type Props = Partial<MetaData>;

const SEOHeader = ({ title, description, keywords, thumbnailUrl }: Props) => {
  const stage =
    process.env.NEXT_PUBLIC_STAGE !== 'production'
      ? `[${process.env.NEXT_PUBLIC_STAGE}]`
      : '';
  const documentTitle = [stage, title, defaultMetaData.title]
    .filter(Boolean)
    .join(' | ');

  return (
    <>
      <Head>
        <title>{documentTitle}</title>

        <link rel="shortcut icon" href={ShortCutIcon.src} />
        <link rel="apple-touch-icon" href={AppleTouchIcon.src} />

        <meta name="description" content={description} />
        <meta
          name="keywords"
          content={defaultMetaData.keywords.concat(keywords || []).join(',')}
        />

        <meta
          property="og:image"
          itemProp="image primaryImageOfPage"
          content={thumbnailUrl || ProgrammersMetaImage.src}
        />
        <meta property="og:title" content={documentTitle} />
        <meta property="og:description" content={description} />

        <meta name="twitter:title" content={documentTitle} />
        <meta name="twitter:description" content={description} />
      </Head>
    </>
  );
};

export default SEOHeader;
