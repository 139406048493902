export const QUERY_KEY = {
  activity: 'activity',
  getstreamToken: 'getstreamToken',
  groupImportablePart: 'groupImportablePart',
  lounge: 'lounge',
  loungeBanner: 'loungeBanner',
  loungeList: 'loungeList',
  myStudyList: 'myStudyList',
  study: 'study',
  studyMembers: 'studyMembers',
  talk: 'talk',
  talkBanner: 'talkBanner',
  talkList: 'talkList',
  user: 'user',
  talkCategory: 'talkCategory',
} as const;

export const MUTATION_KEY = {
  block: 'block',
} as const;
