import { useEffect } from 'react';
import {
  addHackleClickEventListener,
  addHackleReadyEventListener,
  addHackleTrackEventListener,
} from '@/exportables/services/hackle.service';

export const useSetHackleEventListener = () => {
  useEffect(() => {
    if (window.document.body.dataset.hackleEventBounded) return;
    window.document.body.dataset.hackleEventBounded = 'true';

    addHackleClickEventListener();
    addHackleTrackEventListener();
    addHackleReadyEventListener();
  }, []);
};
