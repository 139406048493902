import { useRouter } from 'next/router';
import React, { useMemo } from 'react';
import { Button, LayoutBlogStyle, LayoutHeaderStyle } from './LayoutBlog.style';
import IcKeyboard from '@/assets/svgs/ic-keyboard.svg';
import { useUserContext } from '@/context';

interface Props {
  children: React.ReactNode;
}

const LayoutBlog = ({ children }: Props) => {
  const router = useRouter();
  const { slug: loungeId } = router.query;
  const { isLoading: isUserLoading, userInfo } = useUserContext();

  const isCommunityManager = useMemo(() => {
    if (isUserLoading || userInfo === undefined) return false;
    return Boolean(userInfo?.isCommunityManager);
  }, [isUserLoading, userInfo]);

  return (
    <LayoutBlogStyle>
      <LayoutHeaderStyle>
        <h1>프로그래머스 블로그</h1>
        {isCommunityManager && (
          <Button href={`/blog/${loungeId}/write`} size="large" hasIcon>
            <IcKeyboard />
            작성하기
          </Button>
        )}
      </LayoutHeaderStyle>

      <>{children}</>
    </LayoutBlogStyle>
  );
};

export default LayoutBlog;
