import dayjs, { ManipulateType, QUnitType, OpUnitType, Dayjs } from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isToday from 'dayjs/plugin/isToday';
import relativeTime from 'dayjs/plugin/relativeTime';
import timezone from 'dayjs/plugin/timezone';
import updateLocale from 'dayjs/plugin/updateLocale';
import utc from 'dayjs/plugin/utc';
import 'dayjs/locale/ko';

export type DateTime = string | Date | Dayjs;

dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(advancedFormat);
dayjs.extend(isToday);
dayjs.extend(updateLocale);
dayjs.extend(relativeTime);

export const FORMAT = {
  BASE: 'YYYY-MM-DD',
  SERVER: 'YYYY-MM-DD HH:mm:ss',
  DEFAULT: 'MMMM DD, YYYY', // March 2, 2022
  FULL: 'MMM D, YYYY @ h:mma (Z)', // Mar 2, 2022 at 5:20pm (GMT)
  SHORT: 'MMMM DD', // March 2
};

export const PERIOD = {
  MIL: 'millisecond',
  S: 'second',
  MIN: 'minute',
  D: 'day',
  W: 'week',
  M: 'month',
  Y: 'year',
};

export const datetimeFormatter = (date: DateTime, format = FORMAT.DEFAULT) =>
  dayjs(date).tz().format(format).replace('@', 'at');

export const getTodayDate = (format = FORMAT.DEFAULT) =>
  dayjs().tz().format(format);

export const isDateToday = (date: DateTime) => dayjs(date).isToday();

export const isWeekend = (date: DateTime) =>
  dayjs(date).tz().day() === 0 || dayjs(date).day() === 6;

export const getDayOfWeek = (date: DateTime) => dayjs(date).tz().day();

export const getAdjustDateToWeekDay = (date: DateTime) => {
  const day = getDayOfWeek(date);
  const index = day === 0 ? 2 : 1;
  return dayjs(date).tz().subtract(index, 'day').format(FORMAT.DEFAULT);
};

export const isSameOrBeforeDate = (startDate: DateTime, endDate: DateTime) =>
  dayjs(startDate).isSameOrBefore(endDate);

export const isSameOrAfterDate = (startDate: DateTime, endDate: DateTime) =>
  dayjs(startDate).isSameOrAfter(endDate);

export const isBeforeDate = (startDate: DateTime, endDate: DateTime) =>
  dayjs(startDate).isBefore(endDate);

export const isAfterDate = (startDate: DateTime, endDate: DateTime) =>
  dayjs(startDate).isAfter(endDate);

export const isSameDate = (startDate: DateTime, endDate: DateTime) =>
  dayjs(startDate).isSame(endDate);

export const getAddedDate = (
  startDate: DateTime,
  offset = 1,
  period = PERIOD.D as ManipulateType,
  format = FORMAT.DEFAULT
) => dayjs(startDate).tz().add(offset, period).format(format);

export const getSubtractedDate = (
  startDate: DateTime,
  offset = 1,
  period = PERIOD.D as ManipulateType,
  format = FORMAT.DEFAULT
) => dayjs(startDate).tz().subtract(offset, period).format(format);

export const getStartDayOfMonth = (date: DateTime) =>
  dayjs(date).startOf('month').day();

export const daysInMonth = (date: DateTime) => dayjs(date).daysInMonth();

export const getDiffDates = (
  startDate: DateTime,
  endDate: DateTime,
  period = PERIOD.W as QUnitType | OpUnitType,
  float = true
) => dayjs(endDate).diff(startDate, period, float);

/**
 *
 * @returns dayjs.tz.setDefault의 값과 전혀 무관하게, machine의 system timezone을 리턴한다.
 */
export const guessMyTimezone = () => dayjs.tz.guess();

export const setMyTimezone = (timezone?: string) =>
  dayjs.tz.setDefault(timezone || guessMyTimezone());

export const parseInTimezone = (date: DateTime, timezone?: string) => {
  const mytimezone = timezone ? timezone : guessMyTimezone();
  return dayjs.tz(date, mytimezone);
};

dayjs.locale('ko');
dayjs.updateLocale('ko', {
  relativeTime: {
    future: '%s 후',
    past: '%s 전',
    s: '몇 초',
    m: '1분',
    mm: '%d분',
    h: '1시간',
    hh: '%d시간',
    d: '1일',
    dd: '%d일',
    M: '1달',
    MM: '%d달',
    y: '약 1년',
    yy: '약 %d년',
  },
});

export const timeSince = (datetime: DateTime) => {
  return dayjs(datetime).fromNow();
};
