import { ReactNode } from 'react';
import { useSetHackleEventListener } from '@/hooks/client/useSetHackleEventListener';
import { useSetHackleUser } from '@/hooks/client/useSetHackleUser';

type LayoutProps = {
  children: ReactNode;
};

const Layout = ({ children }: LayoutProps) => {
  useSetHackleEventListener();
  useSetHackleUser();

  return <main style={{ width: '100%', overflowX: 'hidden' }}>{children}</main>;
};

export default Layout;
