import { createInstance } from '@hackler/react-sdk';
import type { HackleEvent } from '@hackler/react-sdk';

export type HackleEventType =
  | string
  | {
      key: HackleEvent['key'];
      properties: HackleEvent['properties'];
    };

const isProduction = process.env.IS_PRODUCTION;
const hackleAppKey = process.env.HACKLE_BROWSER_KEY || '';

export const hackleClient = createInstance(hackleAppKey, {
  debug: !isProduction,
});

/**
 * 아래 코드는 hera-webapp에서 사용하는 핵클 세팅 코드를 참고하여 작성되었습니다.
 *
 * 핵클(hackle)을 사용하는 방식을 프로젝트별로 통일해서 사용할 수 있도록 동일한 세팅과 인터페이스를 제공합니다.
 *
 * @see https://github.com/grepp/hera-webapp/blob/8af24c7d0fe07809357695135e70a5ffd8435949/app/javascript/src/exportables/services/useHackle.ts
 */
interface HackleViewEvent extends Event {
  detail?: string | HackleEvent;
}

export const addHackleTrackEventListener = () => {
  window.document.body.addEventListener(
    'hackle-view',
    (event: HackleViewEvent) => {
      const viewKey = event.detail;
      viewKey && track(viewKey);
    }
  );
};

let hackleReady: Promise<void> | null = null;

export const dispatchHackleEvent = (eventKey: string | HackleEvent) => {
  hackleReady = hackleReady || checkHackleReady();
  hackleReady.then(() => {
    document.body.dispatchEvent(
      new CustomEvent('hackle-view', { detail: eventKey })
    );
  });
};

const checkHackleReady = () => {
  const document = globalThis.window?.document;
  if (!document) return Promise.resolve();

  return new Promise<void>((resolve) => {
    document.body.addEventListener('hackle-ready', () => {
      resolve();
    });
    document.body.dispatchEvent(new CustomEvent('check-hackle-ready'));
  });
};

export const addHackleReadyEventListener = () => {
  const dispatchReadyEvent = () => {
    global.document.body.dispatchEvent(new CustomEvent('hackle-ready'));
  };

  window.document.body.addEventListener(
    'check-hackle-ready',
    dispatchReadyEvent
  );

  dispatchReadyEvent(); // hackle event 전송 준비가 완료되었음을 알리는 이벤트
};

export const addHackleClickEventListener = () => {
  window.document.body.addEventListener('click', (event) => {
    const target = (event.target as HTMLElement).closest(
      '[data-hackle-value]'
    ) as HTMLElement;

    if (!target) return;

    const hackleEventValue = target.dataset.hackleValue;

    if (hackleEventValue) {
      const parsedValue = /{.+}/.test(hackleEventValue)
        ? JSON.parse(hackleEventValue)
        : hackleEventValue;

      track(parsedValue);
    }
  });
};

const track = (hackleEvent: HackleEvent | string) => {
  hackleClient.onReady(() => {
    hackleClient.track(hackleEvent);
  });
};
