import { Component, PropsWithChildren, ReactNode } from 'react';
import NotFound from '../views/NotFound';
import Unauthorized from '../views/Unauthorized';

type ErrorBoundaryState = {
  hasError: boolean;
  status?: number;
};

const errorView: Record<number, ReactNode> = {
  401: <Unauthorized />,
  404: <NotFound />,
};

class ErrorBoundary extends Component<PropsWithChildren, ErrorBoundaryState> {
  constructor(props: PropsWithChildren) {
    super(props);

    this.state = {
      hasError: false,
    };
  }

  static getDerivedStateFromError(error: Error & { status?: number }) {
    return {
      hasError: true,
      status: error?.status,
    };
  }

  componentDidUpdate(
    prevProps: PropsWithChildren,
    prevState: ErrorBoundaryState
  ) {
    if (prevState.hasError) this.setState({ hasError: false });
  }

  render() {
    const { hasError, status } = this.state;
    const { children } = this.props;

    if (!hasError) return children;
    return status ? errorView[status] : null;
  }
}

export default ErrorBoundary;
