import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { THEME_COLORS } from '@/exportables/styles';
import { remCalc } from '@/exportables/utils';

export type ButtonTheme = 'default' | 'danger' | 'grey' | 'black';
export type ButtonSize = 'default' | 'medium' | 'large' | 'small';
interface ButtonProps {
  theme?: ButtonTheme;
  size?: ButtonSize;
  color?: keyof typeof THEME_COLORS;
  backgroundColor?: keyof typeof THEME_COLORS;
  fontWeight?: number;
  bordered?: boolean;
  borderColor?: keyof typeof THEME_COLORS;
  hasIcon?: boolean;
  iconPosition?: 'left' | 'right';
  width?: number;
}

const Button = styled('button', {
  shouldForwardProp: (propName) =>
    propName !== 'hasIcon' &&
    propName !== 'backgroundColor' &&
    propName !== 'bordered' &&
    propName !== 'iconPosition' &&
    propName !== 'borderColor',
})<ButtonProps>`
  box-sizing: border-box;
  border-radius: ${remCalc('4')};
  color: ${({ color }) => (color ? THEME_COLORS[color] : THEME_COLORS.white)};
  background-color: ${({ backgroundColor }) =>
    backgroundColor ? THEME_COLORS[backgroundColor] : THEME_COLORS.blue500};
  font-size: ${remCalc('16')};
  line-height: 150%;
  box-shadow: ${({ bordered, borderColor }) =>
    bordered
      ? `0 0 0 1px ${THEME_COLORS[borderColor || 'blue500']} inset`
      : 'none'};
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: color 0.08s ease-in-out, background-color 0.08s ease-in-out,
    border-color 0.08s ease-in-out, box-shadow 0.08s ease-in-out;

  svg {
    width: ${remCalc('20')};
    height: ${remCalc('20')};
    margin: ${({ iconPosition }) =>
      iconPosition === 'right' ? remCalc('0 0 0 4') : remCalc('0 4 0 0')};
  }

  width: ${({ width }) => (width ? `${width}px` : 'auto')};

  ${({ size, fontWeight, hasIcon }) => {
    switch (size) {
      case 'default':
        return css`
          padding: ${hasIcon ? remCalc('11 14 11 20') : remCalc('11 20')};
          font-weight: ${fontWeight ? fontWeight : 500};
        `;
      case 'medium':
        return css`
          padding: ${remCalc('5 13')};
          font-size: ${remCalc('14')};
        `;
      case 'large':
        return css`
          padding: ${remCalc('11 19')};
          min-width: ${remCalc('100')};
          font-weight: ${fontWeight ? fontWeight : 700};
        `;
      case 'small':
        return css`
          font-size: 14px;
          padding: ${remCalc('6 16')};
          font-weight: ${fontWeight ? fontWeight : 700};
        `;
    }
  }};

  &.disable,
  &:disabled {
    cursor: not-allowed;
    pointer-events: none;
    opacity: 0.4;
  }

  ${({ bordered }) => {
    return css`
      &:hover {
        background-color: ${bordered
          ? THEME_COLORS.blue500
          : THEME_COLORS.blue700};
        color: ${THEME_COLORS.white};
      }
    `;
  }};

  ${({ theme }) => {
    switch (theme) {
      case 'default':
        return css`
          background-color: ${THEME_COLORS.blue500};
          &:hover {
            background-color: ${THEME_COLORS.blue700};
          }
        `;
      case 'grey':
        return css`
          color: ${THEME_COLORS.black};
          background-color: ${THEME_COLORS.blueGrey10};
          border: 1px solid ${THEME_COLORS.border};

          &:hover {
            color: ${THEME_COLORS.black};
            background-color: ${THEME_COLORS.blueGrey20};
          }
        `;
      case 'danger':
        return css`
          background-color: ${THEME_COLORS.red600};
          &:hover {
            background-color: #cc2700;
          }
        `;
      case 'black':
        return css`
          background-color: ${THEME_COLORS.black};
          &:hover {
            background-color: ${THEME_COLORS.black};
          }
        `;
    }
  }};

  ${({ backgroundColor, color }) => {
    switch (backgroundColor) {
      case 'white':
        return css`
          &:hover {
            color: ${color};
            background-color: ${THEME_COLORS.blueGrey10};
          }
        `;
      case 'deepOrange400':
        return css`
          &:hover {
            color: ${color};
            background-color: ${THEME_COLORS.red600};
          }
        `;
    }
  }}
`;

Button.defaultProps = {
  size: 'default',
};

export default Button;
