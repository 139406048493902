import { Post, Study, Talk } from '../models';

const DESCRIPTION_MAX_LENGTH = 100;

export interface MetaData {
  title: string;
  description: string;
  keywords: string[];
  thumbnailUrl?: string;
}

export const defaultMetaData: MetaData = {
  title: '프로그래머스',
  description:
    'SW개발자를 위한 평가, 교육, 채용까지 Total Solution을 제공하는 개발자 성장을 위한 베이스캠프',
  keywords: ['개발자 커뮤니티', '개발자 스터디', '개발자 블로그'],
};

interface MetaInfo {
  [key: string]:
    | Partial<MetaData>
    | ((...args: any[] | any) => Partial<MetaData>);
  show: (...args: any[] | any) => Partial<MetaData>;
}

export const talkMeta: MetaInfo = {
  index: {
    title: '',
    description: defaultMetaData.description,
  },
  write: {
    title: '',
    description: defaultMetaData.description,
  },
  show: (talk: Talk) => {
    return {
      title: talk.title,
      description: talk.content.slice(0, DESCRIPTION_MAX_LENGTH),
    };
  },
  edit: {
    title: '',
    description: defaultMetaData.description,
  },
};

export const studyMeta: MetaInfo = {
  index: {
    title: '스터디',
    description:
      '프로그래머스 커뮤니티에서 개발 스터디를 모집하고 참여해보세요. 함께 할수록 단단하게 성장할 수 있어요. 스터디도 프로그래머스에서.',
  },
  show: (study: Study) => {
    return {
      title: study.title,
      description: study.description.slice(0, DESCRIPTION_MAX_LENGTH),
    };
  },
};

export const blogMeta: MetaInfo = {
  index: {
    title: '',
    description: defaultMetaData.description,
  },
  write: {
    title: '',
    description: defaultMetaData.description,
  },
  show: (post: Post) => {
    return {
      title: post.title,
      description: post.content.slice(0, DESCRIPTION_MAX_LENGTH),
      thumbnailUrl: post.thumbnailUrl,
    };
  },
  edit: {
    title: '',
    description: defaultMetaData.description,
  },
};
