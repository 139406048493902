import { useInfiniteQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import type { NextPageWithLayout } from 'next';
import { Fragment, useEffect, useMemo } from 'react';
import { useInView } from 'react-intersection-observer';
import * as Style from './PostList.style';
import PostListItem from './PostListItem';
import SEOHeader from '@/components/common/SEOHeader';
import Spinner from '@/components/common/Spinner';
import {
  BlogAsideStyle,
  BlogContentStyle,
} from '@/components/layout/LayoutBlog.style';
import SideBar from '@/components/views/blog/Sidebar/Sidebar';
import { blogMeta } from '@/exportables/constants/meta';
import { PostList as PostListModel } from '@/exportables/models';
import { dispatchHackleEvent } from '@/exportables/services/hackle.service';
import { getPosts } from '@/exportables/services/post.api';
import { QUERY_KEY } from '@/exportables/services/queryKey';
import { useHackleDispatchEffect } from '@/hooks/client/useHackleDispatchEffect';

interface PostListProps {
  slug: string;
}

const PostList: NextPageWithLayout<PostListProps> = ({ slug }) => {
  const { ref, inView } = useInView({
    threshold: 0.3,
  });

  const fetchProjects = ({ pageParam = 1 }) => {
    return getPosts(slug, { page: pageParam });
  };

  const { data, isFetching, fetchNextPage, hasNextPage } = useInfiniteQuery<
    PostListModel,
    AxiosError,
    PostListModel
  >({
    queryKey: [QUERY_KEY.lounge, slug],
    queryFn: fetchProjects,
    getNextPageParam: ({ page, totalPages }) => {
      if (Number(page) < totalPages) {
        return Number(page) + 1;
      }
      return null;
    },
  });

  const isEmpty = useMemo(() => {
    return !isFetching && data?.pages?.[0].results.length === 0;
  }, [data, isFetching]);

  useEffect(() => {
    if (inView && hasNextPage) {
      fetchNextPage();
    }
  }, [inView, fetchNextPage, hasNextPage]);

  const currentPage = useMemo(() => {
    return data?.pages.length || 0;
  }, [data]);

  useHackleDispatchEffect({
    key: 'blog_list_viewed',
    properties: {
      page_depth: currentPage,
    },
  });

  if (!data) return null;

  return (
    <>
      <SEOHeader {...blogMeta.index} />

      <BlogContentStyle>
        {data?.pages?.length !== 0 && (
          <Style.List>
            {data?.pages?.map((page) => (
              <Fragment key={page.page}>
                {page?.results?.map((post) => (
                  <li
                    key={post.id}
                    data-hackle-value={JSON.stringify({
                      key: 'blog_list_clicked',
                      properties: {
                        post_id: post.id,
                        category: post.category,
                      },
                    })}
                  >
                    <PostListItem key={post.id} item={post} />
                  </li>
                ))}
              </Fragment>
            ))}
          </Style.List>
        )}
        <div ref={ref} />

        {isEmpty && (
          <Style.Empty>
            <p>아직 등록된 이야기가 없어요. 여러분의 이야기를 들려주세요!</p>
          </Style.Empty>
        )}

        {isFetching && (
          <Style.SpinnerWrapper>
            <Spinner />
          </Style.SpinnerWrapper>
        )}
      </BlogContentStyle>

      <BlogAsideStyle>
        <SideBar
          bannerHtml={data.pages[0].banner?.contents}
          onBannerClick={(targetUrl) =>
            dispatchHackleEvent({
              key: 'blog_list_banner_clicked',
              properties: {
                target_url: targetUrl,
              },
            })
          }
        />
      </BlogAsideStyle>
    </>
  );
};

export default PostList;
