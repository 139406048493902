import { SpinnerContainer, SpinnerStyle, SpinnerBall } from './Spinner.style';
import type { SpinnerProps } from './Spinner.style';

const Spinner = ({ backgroundColor, size, place = 'center' }: SpinnerProps) => {
  return (
    <SpinnerContainer place={place}>
      <SpinnerStyle backgroundColor={backgroundColor} size={size} place={place}>
        <SpinnerBall />
      </SpinnerStyle>
    </SpinnerContainer>
  );
};

export default Spinner;
