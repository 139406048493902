import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { ErrorMessage } from '../FormErrorMessage/FormErrorMessage.style';
import FormLabel from '../FormLabel';
import { remCalc } from '@/exportables/utils';

interface FormControlProps {
  required?: boolean;
}

export const FormControl = styled.div<FormControlProps>`
  display: flex;
  flex-direction: column;

  ${FormLabel} {
    margin-bottom: ${remCalc('8')};
  }

  ${ErrorMessage} {
    margin-top: ${remCalc('8')};
  }

  ${({ required }) =>
    required &&
    css`
      ${FormLabel} {
        &::after {
          content: '*';
          color: #f03d0c;
          margin-left: ${remCalc('2')};
        }
      }
    `}
`;

/* ${FormHint} {
    margin-top: ${remCalc('5')};
  }

  ${ErrorMessage} {
    margin-top: ${remCalc('8')};
  } */
