import type { HTMLAttributes, PropsWithChildren } from 'react';
import { FormControl } from './FormControl.style';

interface Props extends HTMLAttributes<HTMLDivElement> {
  required?: boolean;
  className?: string;
}

const FormControlContainer = ({
  children,
  required,
  className,
  ...rest
}: PropsWithChildren<Props>) => {
  return (
    <FormControl required={required} {...rest} className={className}>
      {children}
    </FormControl>
  );
};

export default FormControlContainer;
