import Image from 'next/image';
import { useEffect, useState } from 'react';
import * as Style from './Sidebar.style';
import MobileBanner from '@/assets/images/banner/img-blog-banner-m.png';
import TabletBanner from '@/assets/images/banner/img-blog-banner-t.png';
import VerticalBanner from '@/assets/images/banner/img-blog-banner-w.png';
import { mdDown, smDown } from '@/exportables/styles';
import useResize from '@/hooks/client/useResize';

const targetUrl = 'https://school.programmers.co.kr/learn/KDT?referer=blog';

type Props = {
  onBannerClick?: (targetUrl: string) => void;
  bannerHtml: string | undefined;
};

const SideBar = ({ onBannerClick, bannerHtml }: Props) => {
  const [currentMediaType, setCurrentMediaType] = useState<
    'tablet' | 'mobile' | 'web'
  >();
  const { windowSize } = useResize();

  useEffect(() => {
    if (windowSize?.innerWidth! < Number(smDown)) {
      setCurrentMediaType('mobile');
    } else if (windowSize?.innerWidth! < Number(mdDown)) {
      setCurrentMediaType('tablet');
    } else {
      setCurrentMediaType('web');
    }
  }, [windowSize]);

  return (
    <Style.Container>
      <Style.Banner
        href={targetUrl}
        target="_blank"
        onClick={() => onBannerClick?.(targetUrl)}
      >
        {bannerHtml && <div dangerouslySetInnerHTML={{ __html: bannerHtml }} />}
        {!bannerHtml && (
          <>
            {currentMediaType === 'web' && (
              <Image src={VerticalBanner} alt="web banner" height={576} />
            )}
            {currentMediaType === 'tablet' && (
              <Image src={TabletBanner} alt="tablet banner" height={86} />
            )}
            {currentMediaType === 'mobile' && (
              <Image src={MobileBanner} alt="mobile banner" height={50} />
            )}
          </>
        )}
      </Style.Banner>
    </Style.Container>
  );
};

export default SideBar;
