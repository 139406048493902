import { css } from '@emotion/react';
import styled from '@emotion/styled';
import Link from 'next/link';
import {
  belowMobile,
  flexStartX,
  flexColumnStartY,
  font,
  flexCenter,
  textEllipsis,
} from '@/exportables/styles';
import { THEME_COLORS } from '@/exportables/styles/colors';
import { remCalc } from '@/exportables/utils';

const textEllipsisStyle = (line: number) => css`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: ${line};
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const Container = styled(Link)`
  position: relative;
  width: 100%;
  ${flexStartX};
  height: 100%;
  min-height: ${remCalc('136')};

  h2 {
    ${flexStartX}
    ${font(18, 700, '#000000', 1, '150%')};
    ${textEllipsisStyle(2)};
    word-break: keep-all;
    margin-bottom: 4px;
  }

  h3 {
    ${font(14, 400, '#777777', 1, '150%')};
    ${textEllipsisStyle(3)};
    word-break: keep-all;
    margin-bottom: 12px;
  }

  :hover,
  :focus {
    h2 {
      text-decoration: underline;
    }
  }

  ${belowMobile(css`
    ${flexColumnStartY};

    h2 {
      ${textEllipsisStyle(3)};
      font-size: 16px;
    }
  `)};
`;

export const Thumbnail = styled.div`
  position: relative;
  max-width: 260px;
  max-height: 136px;
  width: 100%;
  aspect-ratio: 1200 / 628;
  margin-right: 16px;

  > img {
    border-radius: 5px;
    object-fit: cover;
    object-position: center;
  }

  ${belowMobile(css`
    max-width: 100%;
    width: 100%;
    max-height: unset;
    margin-right: 0px;
    margin-bottom: 16px;

    > img {
      width: 100%;
      height: 100%;
    }
  `)};
`;

export const OverlapImage = styled.div`
  ${flexCenter};
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  border-radius: 5px;

  ${belowMobile(css`
    width: 100%;
  `)};
`;

export const LockedIcon = styled.div`
  text-align: center;
`;

export const Content = styled.div`
  position: relative;
  width: calc(100% - 260px - 16px);

  ${belowMobile(css`
    max-width: 100%;
    width: 100%;
  `)};
`;

export const InfoWrap = styled.div`
  position: relative;
  width: 100%;
  ${flexStartX};
  align-items: center;
  gap: ${remCalc('8')};
  margin: ${remCalc('8 0 0')};
`;

export const Divider = styled.span`
  width: 1px;
  height: 10px;
  border-right: 1px solid #aab6c2;
`;

export const Category = styled.span`
  ${textEllipsisStyle(1)};
  ${font(12, 500, THEME_COLORS.blue500, 1, '150%')};
  max-width: 40%;
`;

export const UserName = styled.span`
  ${textEllipsis};
  ${font(12, 500, THEME_COLORS.textSecondary, 1, '150%')};
  max-width: 40%;
`;

export const CreatedAt = styled.span`
  ${font(12, 500, THEME_COLORS.textSecondary, 1, '150%')};
  max-width: 55px;
`;

interface NewBadgeProps {
  size?: 'small' | 'large';
}

const CommonBadge = styled.span<NewBadgeProps>`
  ${font(14, 700, THEME_COLORS.white)}
  ${({ size }) => size === 'large' && font(16, 700, THEME_COLORS.white)}

  position: relative;
  display: inline-block;
  text-align: center;
  width: ${({ size }) => (size === 'large' ? remCalc('20') : remCalc('16'))};
  height: ${({ size }) => (size === 'large' ? remCalc('20') : remCalc('16'))};
  line-height: ${({ size }) =>
    size === 'large' ? remCalc('20') : remCalc('16')};
  border-radius: ${remCalc('4')};
  background-color: #ff850a;
  margin-right: ${({ size }) =>
    size === 'large' ? remCalc('8') : remCalc('4')};
  top: ${({ size }) => (size === 'large' ? remCalc('-2.5') : remCalc('-1'))};
`;

export const NewBadge = styled(CommonBadge)`
  background-color: #ff850a;
`;

export const HiddenBadge = styled(CommonBadge)`
  width: auto;
  height: auto;
  padding: 4px 6px;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%; /* 12px */
  background-color: ${THEME_COLORS.textPrimary};
`;

export const UpdateBadge = styled(CommonBadge)`
  background-color: ${THEME_COLORS.green500};
`;
