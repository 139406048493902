import { css } from '@emotion/react';
import styled from '@emotion/styled';
import ImgEmpty from '@/assets/images/img-community-empty-list.png';
import { flexContainer, THEME_COLORS } from '@/exportables/styles';
import { belowTablet, font } from '@/exportables/styles/mixins';
import { remCalc } from '@/exportables/utils';

export const List = styled.ul`
  width: 100%;
  position: relative;

  > li {
    margin-bottom: 32px;
  }
`;

export const Empty = styled.div`
  ${flexContainer('flex-end', 'center', 'column')}
  width: 100%;
  height: ${remCalc('200')};
  background-image: url(${ImgEmpty.src});
  background-repeat: no-repeat;
  background-position: center top;
  gap: ${remCalc('32')};
  margin: ${remCalc('28 0')};

  p {
    ${font(16, 400, THEME_COLORS.textParagraph, 1, '140%')};
    text-align: center;
    word-break: keep-all;

    ${belowTablet(css`
      ${font(14)};
    `)}
  }
`;

export const SpinnerWrapper = styled.div`
  ${flexContainer('center', 'center', 'column')}
  width: 100%;
`;
