import Image from 'next/image';
import * as Style from './PostListItem.style';
import defaultThumbnailImage from '@/assets/images/img-default-thumbnail.png';
import IcLocked from '@/assets/images/img-thumbnail-lock.png';
import { PostListItem as Item } from '@/exportables/models';
import { timeSince } from '@/exportables/utils';

type PostListItemProps = {
  item: Item;
};

const PostListItem = ({ item }: PostListItemProps) => {
  const {
    id,
    title,
    category,
    content,
    userName,
    createdAt,
    newTalk: newPost,
    thumbnailUrl,
    isPublished,
  } = item;

  return (
    <Style.Container href={`/post/${id}`}>
      <Style.Thumbnail>
        {isPublished === false && (
          <Style.OverlapImage>
            <Image src={IcLocked} alt="locked" width={76} height={76} />
          </Style.OverlapImage>
        )}
        <Image
          src={thumbnailUrl || defaultThumbnailImage}
          alt={`Image Thumbnail ${title}`}
          width={260}
          height={136}
        />
      </Style.Thumbnail>
      <Style.Content>
        <h2 aria-description="글 제목">
          {newPost && <Style.NewBadge>N</Style.NewBadge>}
          {isPublished === false && (
            <Style.HiddenBadge>비공개</Style.HiddenBadge>
          )}
          <span>{title}</span>
        </h2>
        <h3 aria-description="글 내용">{content}</h3>

        <Style.InfoWrap>
          <Style.Category aria-description="글 카테고리">
            {category}
          </Style.Category>
          <Style.Divider />
          <Style.UserName aria-description="유저 이름">
            {userName}
          </Style.UserName>
          <Style.Divider />
          <Style.CreatedAt aria-description="글 생성일">
            {createdAt ? timeSince(createdAt) : '발행 전'}
          </Style.CreatedAt>
        </Style.InfoWrap>
      </Style.Content>
    </Style.Container>
  );
};

export default PostListItem;
