import { GetServerSidePropsContext } from 'next';
import { createServerSideClient, heraClient, axiosClient } from './http';
import { Post, PostList } from '@/exportables/models';

export interface PatchPostPayload {
  title: string;
  content: string;
}

export interface PostCreatePayload extends PatchPostPayload {
  categoryId: number;
  isPublished: boolean;
  thumbnail?: File;
}

interface FetchPostsParams {
  categoryId?: number;
  page?: number;
}

export const getPosts = async (
  loungeId: string,
  params?: FetchPostsParams,
  ctx?: GetServerSidePropsContext
) => {
  const axioClient = ctx ? createServerSideClient(ctx) : heraClient;
  const { data } = await axioClient.get<PostList>(
    `/api/community/lounges/${loungeId}/talks`,
    { params }
  );

  return data;
};

export const createPost = async (loungeId: number, formData: FormData) => {
  const { data } = await heraClient.post<{ talkId: string }>(
    `/api/community/lounges/${loungeId}/talks`,
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }
  );

  return data;
};

export const getPost = async (
  talkId: string,
  ctx?: GetServerSidePropsContext
) => {
  const axioClient = ctx ? createServerSideClient(ctx) : heraClient;
  const { data } = await axioClient.get<Post>(`/api/community/talks/${talkId}`);

  return data;
};

export const patchPost = async (talkId: string, formData: FormData) => {
  const { data } = await heraClient.patch<PatchPostPayload>(
    `/api/community/talks/${talkId}`,
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }
  );

  return data;
};

export const deletePost = async (talkId: string) => {
  const { data } = await heraClient.delete(`/api/community/talks/${talkId}`);

  return data;
};

export const fetchPresignedUrls = async (fileName: string, path: string) => {
  const { data } = await heraClient.post<{ url: string; public_url: string }>(
    '/api/presigned_urls',
    {
      path,
      file_name: fileName,
    }
  );

  return data;
};

export const putImageContentType = async (url: string, file: File) => {
  return await axiosClient.put(url, file, {
    headers: {
      'Content-Type': file.type,
    },
  });
};
