import { useEffect } from 'react';
import { dispatchHackleEvent } from '@/exportables/services/hackle.service';
import type { HackleEventType } from '@/exportables/services/hackle.service';

export const useHackleDispatchEffect = (event: HackleEventType) => {
  const isObjectEvent = typeof event === 'object';
  const serializedEvent = isObjectEvent ? JSON.stringify(event) : event;

  useEffect(() => {
    const deserializedEvent = isObjectEvent
      ? JSON.parse(serializedEvent)
      : serializedEvent;

    dispatchHackleEvent(deserializedEvent);
  }, [isObjectEvent, serializedEvent]);
};
