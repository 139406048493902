import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { mediaBreakPoints, THEME_COLORS } from '@/exportables/styles';
import { remCalc } from '@/exportables/utils';

export interface SpinnerProps {
  size?: number;
  backgroundColor?: keyof typeof THEME_COLORS;
  place?: 'center' | 'top';
}

export const SpinnerContainer = styled.div<{ place: 'center' | 'top' }>`
  display: flex;

  ${({ place }) => {
    switch (place) {
      case 'top':
        return css`
          justify-content: center;
          padding-top: ${remCalc('148')};
        `;
      case 'center':
        return css`
          justify-content: center;
          align-items: center;
        `;
    }
  }}

  height: 100%;
`;

export const SpinnerStyle = styled('div', {
  shouldForwardProp: (propName) => propName !== 'backgroundColor',
})<SpinnerProps>`
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  animation: spin 1s linear infinite;
  background: transparent;
  border-radius: 50%;
  height: ${({ size }) => remCalc(`${size || 28}`)};
  width: ${({ size }) => remCalc(`${size || 28}`)};
  position: relative;

  @media ${mediaBreakPoints.sm_down} {
    height: ${remCalc('28')};
    width: ${remCalc('28')};
  }

  .ball {
    position: absolute;
    background: ${THEME_COLORS.blue500};
    border-radius: 50%;
    height: ${remCalc('4')};
    width: ${remCalc('4')};
    top: 0;
    left: 50%;
    transform: translate(-50%, 0);
  }

  &::before,
  &::after {
    content: '';
    position: absolute;
  }

  &::before {
    border-radius: 50%;
    background: conic-gradient(
      from 0deg at 50% 50%,
      rgba(0, 123, 255, 0) 0deg,
      ${THEME_COLORS.blue500} 360deg
    );
    background-repeat: no-repeat;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  &::after {
    background: ${({ backgroundColor }) =>
      backgroundColor ? THEME_COLORS[backgroundColor] : THEME_COLORS.white};
    border-radius: 50%;
    top: 50%;
    left: 50%;
    width: ${({ size }) => remCalc(`${(size || 28) - 8}`)};
    height: ${({ size }) => remCalc(`${(size || 28) - 8}`)};
    transform: translate(-50%, -50%);

    @media ${mediaBreakPoints.sm_down} {
      height: ${remCalc('20')};
      width: ${remCalc('20')};
    }
  }
`;

export const SpinnerBall = styled.div`
  position: absolute;
  background: ${THEME_COLORS.blue500};
  border-radius: 50%;
  height: ${remCalc('4')};
  width: ${remCalc('4')};
  top: 0;
  left: 50%;
  transform: translate(-50%, 0);
`;
