import { useEffect, useState, useCallback } from 'react';

type WindowSize = Pick<
  Window,
  'innerHeight' | 'innerWidth' | 'outerHeight' | 'outerWidth' | 'screen'
>;

const useResize = (handler?: (e: Event) => void) => {
  if (typeof globalThis.window === 'undefined')
    return {
      windowSize: null,
    };

  const { innerHeight, innerWidth, outerHeight, outerWidth, screen } =
    globalThis.window;
  const [windowSize, setWindowSize] = useState<WindowSize>({
    innerHeight,
    innerWidth,
    outerHeight,
    outerWidth,
    screen,
  });

  const resizeHandler = useCallback(
    (event: Event) => {
      const { innerHeight, innerWidth, outerHeight, outerWidth, screen } =
        (event.currentTarget as Window)!;
      handler?.(event);
      setWindowSize({
        innerHeight,
        innerWidth,
        outerHeight,
        outerWidth,
        screen,
      });
    },
    [handler]
  );

  useEffect(() => {
    window.addEventListener('resize', resizeHandler);

    return () => {
      window.removeEventListener('resize', resizeHandler);
    };
  }, []);

  return {
    windowSize,
  };
};

export default useResize;
