import { dehydrate } from '@tanstack/react-query';
import { GetServerSideProps } from 'next';
import React from 'react';
import LayoutBlog from '@/components/layout/LayoutBlog';
import PostList from '@/components/views/blog/PostList/PostList';
import { getPosts } from '@/exportables/services/post.api';
import { queryClient } from '@/exportables/services/queryClient';
import { QUERY_KEY } from '@/exportables/services/queryKey';

type Props = {
  slug: string;
};

export const getServerSideProps: GetServerSideProps<{}, Props> = async (
  ctx
) => {
  const slug = ctx.query.slug as string;
  const fetchParams = {
    page: 1,
  };

  await queryClient.prefetchInfiniteQuery([QUERY_KEY.lounge, slug], () =>
    getPosts(slug, fetchParams, ctx)
  );

  return {
    props: {
      slug,
      dehydratedState: JSON.parse(JSON.stringify(dehydrate(queryClient))),
    },
  };
};

PostList.getLayout = function getLayout(page: React.ReactElement) {
  return <LayoutBlog>{page}</LayoutBlog>;
};

export default PostList;
