import { css } from '@emotion/react';
import styled from '@emotion/styled';
import Link from 'next/link';
import CommonButton from '@/components/common/Button';
import {
  baseContainer,
  belowLgDown,
  belowMdDown,
  belowTablet,
  flexBetweenX,
  flexColumnStartY,
  font,
  THEME_COLORS,
  containerPadding,
} from '@/exportables/styles';
import { remCalc } from '@/exportables/utils';

const asideWidth = 288;

export const LayoutBlogStyle = styled.div`
  ${baseContainer}
  ${flexBetweenX}
  gap: ${remCalc('60')};
  position: relative;

  ${belowTablet(css`
    padding: ${containerPadding.y} ${containerPadding.x};
  `)}

  ${belowLgDown(css`
    overflow-y: scroll;
  `)}

  ${belowMdDown(css`
    flex-direction: column;
    gap: ${remCalc('24')};
  `)}
`;

export const LayoutHeaderStyle = styled.header`
  ${flexBetweenX};
  width: 100%;
  align-items: center;
  gap: ${remCalc('16')};
  position: absolute;
  top: ${remCalc('48')};
  width: calc(100% - ${remCalc('288')} - ${remCalc('100')});
  z-index: 1;

  h1 {
    ${font(20, 700, '#000000', 1, '140%')};
    word-break: keep-all;
  }

  ${belowTablet(css`
    max-width: 812px;
    width: 100%;
  `)}

  ${belowMdDown(css`
    padding: 0;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    position: static;
  `)}
`;

export const BlogContentStyle = styled.main`
  max-width: 812px;
  width: 100%;
  position: relative;
  padding: ${remCalc('82 0 0 0')};

  ${belowMdDown(css`
    margin: 0;
    order: 2;
    padding: ${remCalc('0')};
  `)}
`;

export const BlogAsideStyle = styled.aside`
  ${flexColumnStartY};
  max-width: ${remCalc(asideWidth + '')};
  width: 100%;
  flex-shrink: 0;

  ${belowMdDown(css`
    width: 100%;
    max-width: 100%;
    gap: ${remCalc('8')};
    order: 1;
  `)}
`;

export const Button = styled(CommonButton.withComponent(Link))`
  flex-shrink: 0;

  svg {
    width: unset;
    height: unset;
  }

  &:active,
  &:focus,
  &:visited {
    color: ${THEME_COLORS.white};
  }
`;
