import styled from '@emotion/styled';
import Link from 'next/link';

export const Container = styled.div`
  width: 100%;
  position: relative;
  overflow: hidden;
`;

export const Banner = styled(Link)`
  img {
    width: 100%;
    height: auto;
  }
`;
