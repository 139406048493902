import { Global } from '@emotion/react';
import { HackleProvider } from '@hackler/react-sdk';
import * as Sentry from '@sentry/nextjs';
import {
  Hydrate,
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query';
import type { NextComponentType } from 'next';
import { AppLayoutProps, AppContext, AppInitialProps } from 'next/app';
import Script from 'next/script';
import { ReactNode } from 'react';
import 'stream-chat-react/dist/css/index.css';
import 'toastify-js/src/toastify.css';
import ErrorBoundary from '@/components/common/ErrorBoundary';
import Layout from '@/components/layout/Layout';
import { ModalProvider } from '@/context/modalContext';
import { UserProvider } from '@/context/userContext';
import { hackleClient } from '@/exportables/services/hackle.service';
import { globalStyle } from '@/exportables/styles';
import { disableReactDevTools } from '@/exportables/utils/disableReactDevtools';
import '@/exportables/styles/fonts.scss';
import '@/exportables/styles/reset.scss';

process.env.NEXT_PUBLIC_STAGE !== 'dev' &&
  Sentry.init({
    dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
    environment: process.env.NEXT_PUBLIC_STAGE,
    tracesSampler: (samplingContext) => {
      if (
        samplingContext?.transactionContext?.name === 'GET /api/healthcheck'
      ) {
        return 0.0;
      }
      return 0.01;
    },
  });

const queryClientOptions = {
  defaultOptions: {
    queries: {
      retry: 0,
      refetchOnWindowFocus: false,
    },
  },
};

const queryClient = new QueryClient(queryClientOptions);

const isProduction = process.env.NEXT_PUBLIC_STAGE === 'production';

if (isProduction) {
  disableReactDevTools();
}

const App: NextComponentType<AppContext, AppInitialProps, AppLayoutProps> = ({
  Component,
  pageProps,
}: AppLayoutProps) => {
  const getLayout = Component.getLayout || ((page: ReactNode) => page);

  return (
    <>
      {isProduction && (
        <Script
          id="gtm"
          strategy="afterInteractive"
          dangerouslySetInnerHTML={{
            __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});
              var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';
              j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
              })(window,document,'script','dataLayer','GTM-NT8HLJD');`,
          }}
        />
      )}

      <Global styles={globalStyle} />

      <HackleProvider hackleClient={hackleClient} supportSSR>
        <QueryClientProvider client={queryClient}>
          <Hydrate state={pageProps.dehydratedState}>
            <ModalProvider>
              <UserProvider>
                <ErrorBoundary>
                  <Layout>{getLayout(<Component {...pageProps} />)}</Layout>
                </ErrorBoundary>
              </UserProvider>
            </ModalProvider>
          </Hydrate>
        </QueryClientProvider>
      </HackleProvider>
    </>
  );
};

export default App;
